import './styles.sass'
import React, { Component } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import Page from 'src/components/ui/page'
import Image from 'src/components/ui/image'
import Button from 'src/components/ui/button'
import TextLink from 'src/components/ui/text-link'
import backgroundSrc01 from 'src/images/home-background-01.png'
import backgroundLogoSrc from 'src/images/dark-charm-background-logo.svg'
import backgroundSrc02 from 'src/images/home-background-02.png'
import createSolutionsSrc from 'src/images/creative-solutions-icon.png'
import intuitiveDesignSrc from 'src/images/intuitive-design-icon.png'
import swiftDeliverySrc from 'src/images/swift-delivery-icon.png'
import expertiseSrc from 'src/images/expertise-icon.png'
import empathySrc from 'src/images/empathy-icon.png'
import efficiencySrc from 'src/images/efficiency-icon.png'

export default class Home extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    className: '',
    style: null,
  }

  get className() {
    const { className } = this.props
    return c('dc-home', className)
  }

  render() {
    const { style } = this.props
    const { className } = this
    return (
      <Page className={className} style={style} id="home">
        <section className="hero">
          <Image
            className="background"
            src={backgroundSrc01}
            alt="Dark Charm Hero Background Image"
          />
          <Image
            className="background-logo"
            src={backgroundLogoSrc}
            alt="Dark Charm Logo Background Image"
          />
          <div className="content">
            <h4 className="copy-1">Let’s MAKE MAGIC TOGETHER</h4>
            <h1 className="copy-2">
              We deliver intuitive and beautiful digital experiences.
            </h1>
          </div>
        </section>
        <section className="below-fold">
          <Image
            className="background"
            src={backgroundSrc02}
            alt="Below the Fold Background Image"
          />
          <section className="full-service">
            <div className="heading">
              <h2>
                We’re the full-service digital partner you’ve been looking for.
              </h2>
            </div>
            <div className="services">
              <div className="service icon-left creative-solutions">
                <div className="icon-wrap">
                  <Image
                    className="icon"
                    src={createSolutionsSrc}
                    alt="Creative Solutions Icon"
                  />
                </div>
                <div className="copy">
                  <h3>Creative Solutions</h3>
                  <p>
                    We delve deeply into our clients’ project goals to create
                    and implement pragmatic and ingenious solutions to their
                    problems.
                  </p>
                </div>
              </div>
              <div className="service icon-right intuitive-design">
                <div className="copy">
                  <h3>Intuitive Design</h3>
                  <p>
                    Our revolutionary approach to design and development is an
                    alchemy of art and technology synthesized to conjure elegant
                    and intuitive designs.
                  </p>
                </div>
                <div className="icon-wrap">
                  <Image
                    className="icon"
                    src={intuitiveDesignSrc}
                    alt="Intuitive Design Icon"
                  />
                </div>
              </div>
              <div className="service icon-left swift-delivery">
                <div className="icon-wrap">
                  <Image
                    className="icon"
                    src={swiftDeliverySrc}
                    alt="Swift Delivery Icon"
                  />
                </div>
                <div className="copy">
                  <h3>Swift Delivery</h3>
                  <p>
                    We apply efficient processes, effective project management,
                    and high-quality talent to ensure every client’s project is
                    delivered on time.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="featured-project">
            <div className="heading">
              <h2>Featured Project</h2>
              <TextLink className="link" to="/work" selected={true}>
                VIEW ALL WORK →
              </TextLink>
            </div>
            <div className="project">
              <div className="video">
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/335711468"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="description">
                <div className="client">
                  <h4>Partner</h4>
                  <h2>IQT Labs</h2>
                </div>
                <div className="project-type">
                  <div className="block">
                    <h4>PROJECT</h4>
                    <p>The Powerful Combination of Poseidon & CRviz</p>
                  </div>
                  <div className="block">
                    <h4>TYPE</h4>
                    <p>Design, Animation, Production Management</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="our-sources">
            <div className="heading">
              <h4>The Source of Dark Charm</h4>
              <h2>
                What makes working with Dark Charm such a satisfying experience?
              </h2>
            </div>
            <div className="sources">
              <div className="source icon-left expertise">
                <div className="icon-wrap">
                  <Image
                    className="icon"
                    src={expertiseSrc}
                    alt="Expertise Icon"
                  />
                </div>
                <div className="copy">
                  <h3>Expertise</h3>
                  <p>
                    Designers, engineers, and entrepreneurs appreciate our
                    expertise and our detail-oriented execution, which comes
                    from years of experience in the tech industry.
                  </p>
                </div>
              </div>
              <div className="source icon-right empathy">
                <div className="copy">
                  <h3>Empathy</h3>
                  <p>
                    As digital natives and entrepreneurs, we speak your language
                    and understand your goals and challenges. We also take time
                    to listen and share your vision of what a successful outcome
                    will look like.
                  </p>
                </div>
                <div className="icon-wrap">
                  <Image className="icon" src={empathySrc} alt="Empathy Icon" />
                </div>
              </div>
              <div className="source icon-left efficiency">
                <div className="icon-wrap">
                  <Image
                    className="icon"
                    src={efficiencySrc}
                    alt="Efficiency Icon"
                  />
                </div>
                <div className="copy">
                  <h3>Efficiency</h3>
                  <p>
                    Once we know what you want, we work quickly to get your
                    solution in your hands when you need it, and our optimized
                    work processes ensure that your costs are low, too.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="our-services">
            <div className="heading">
              <h2>Our Services</h2>
            </div>
            <div className="services">
              <div className="service">
                <h4>DEVELOPMENT</h4>
                <p>
                  Specializing in custom websites, our full-stack developers focus on creating elegant code that works like a charm. We’re equipped
                  to work across a variety of platforms, and our iterative
                  development process ensures swift delivery of your solution.
                </p>
              </div>
              <div className="service">
                <h4>DESIGN</h4>
                <p>
                  Our designs are intriguing and exciting. They’re functional
                  and elegant. Whether you’re in need of a logical information
                  architecture or an exquisite interface, we design digital
                  experiences that are functional, flawless, and unforgettable.
                </p>
              </div>
              <div className="service">
                <h4>UI / UX</h4>
                <p>
                  To make your target customer’s experience enjoyable, you need
                  a product that functions intuitively. Through thoughtful
                  strategies, prototyping, and execution, we create UI that
                  makes UX seamless and natural.
                </p>
              </div>
              <div className="service">
                <h4>ANIMATION</h4>
                <p>
                  We love putting gorgeous designs in motion! Our curious minds
                  are excited to bring your ideas to life through storytelling
                  and animation, whether you're introducing a product,
                  explaining a complex subject, or exploring new ways to engage
                  your viewers.
                </p>
              </div>
              <div className="service">
                <h4>PROJECT MANAGEMENT</h4>
                <p>
                  You need your project completed on time and on budget, and you
                  also need to achieve your established project goals. We use
                  clear communication, careful risk management, and logical
                  processes to transform your initial concept into an
                  unparalleled success.
                </p>
              </div>

              <div className="service">
                <h4>PRODUCT MANAGEMENT</h4>
                <p>
                  We offer detail-oriented product management to businesses
                  eager to launch the next big thing, whether it’s a bespoke website, a
                  video game, or a digital dashboard. From the development of
                  product roadmaps to market testing, we apply agile and lean
                  management practices that ensure your new product is
                  everything you imagined it would be.
                </p>
              </div>
            </div>
          </section>
          <section className="get-in-touch">
            <div className="heading">
              <h2>Let’s make magic together.</h2>
            </div>
            <a href="mailto:contact@darkcharm.io">
              <Button className="button">GET IN TOUCH</Button>
            </a>
          </section>
        </section>
      </Page>
    )
  }
}
