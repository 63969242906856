import './styles.sass'
import React, { Component } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'

export default class Button extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
  }

  static defaultProps = {
    className: '',
    style: null,
    children: null,
  }

  get className() {
    const { className } = this.props
    return c('dc-button', className)
  }

  get rest() {
    const props = { ...this.props }
    delete props.children
    delete props.className
    delete props.style
    return props
  }

  render() {
    const { style, children } = this.props
    const { className, rest } = this
    return (
      <div {...rest} className={className} style={style}>
        {children}
      </div>
    )
  }
}
